Beacon.JqueryValidators = {
    initialize: function () {
        jQuery.validator.addMethod('zipCode', this.zipCode, 'Please enter a valid 5- or 9-digit ZIP Code™.');

        

        if ($('#Form1').find('.beacon-captcha').length > 0) {

            /*
             * If a form module seeks to use reCAPTCHA, this is the way to do it:
             *   *  At least 1 element in the module must include the class 'beacon-captcha'.
             *   *  When the module calls Beacon.JqueryValidators.beaconModuleValidator(), it must pass 'true' as the 2nd
             *      argument.
             *   *  Server-side, the module should check for the "g-recaptcha-response" in the request's form data. It should pass
             *      that on to Beacon.RecaptchaHelper.ValidateInvisibleRecaptchaWithMothership(). That function will indicate
             *      whether or not the request passed the reCAPTCHA test.
             *      
             * This is how it works:
             * 
             * Though there are many form _modules_ in Beacon, there can only be 1 <form/> element: Form1. This is required for
             * Beacon to work. And since a <form/> may only have 1 jQuery validator at a time, if multiple form modules try to
             * attach validators, they will conflict. Beacon.JqueryValidators.beaconModuleValidator() manages this to an extent.
             * Validators are not attached until _after_ a user tries to submit, which, though not ideal, is the only way to make
             * this work.
             * 
             * If that call to Beacon.JqueryValidators.beaconModuleValidator() also asks for a captcha verification, then the
             * validator will also have a submit handler.
             * 
             * After the original event propagates up to Form1, the validator will run, and that handler will be executed if the
             * validator determines that the form is ready for submission. The handler will check to see if the reCAPTCHA has
             * been executed. Seeing that it has not, the handler will manually trigger it with grecaptcha.execute(). Crucially,
             * the form will _not_ be submitted at that point.
             * 
             * Instead, reCAPTCHA will do whatever, and then it will execute a callback. Since it demands that that callback be
             * in the global namespace, it is so defined here: beaconCaptchaSubmit(). That callback will simply call
             * requestSubmit() on Form1. It _must_ call requestSubmit() rather than submit() because the latter does not trigger
             * a form submission in the same way. If submit() is used, the form will post, but ASP will not know which button was
             * pressed, and its server-side logic will not be triggered.
             * 
             * After the callback triggers the form submission (again), the validator will validate the form again, and the submit
             * handler will be executed for the second time. This time, it will observe that reCAPTCHA has been run, and the form
             * is ready to submit. It will post the form as usual.
             */

            window.beaconCaptchaLoad = function () {
                let captchaElement = $('#Form1').find('div[data-sitekey]');
                if (captchaElement.length < 1) {
                    return;
                }

                grecaptcha.render(captchaElement[0], {
                    'sitekey': $(captchaElement).attr('data-sitekey'),
                    'callback': 'beaconCaptchaSubmit'
                });
            };

            window.beaconCaptchaSubmit = function () {
                /* We have to use requestSubmit(), _not_ submit(). The latter will not trigger the submit event in the same
                 * way, and the difference is such that ASP.NET will not know which button originally triggered this event.
                 */
                document.getElementById('Form1').requestSubmit();
            }
        } else {
            // No elements on the page have requested CAPTCHA, so don't bother doing anything here
            window.beaconCaptchaLoad = function () {
                return;
            }
        }
    },
    currentValidator: null,
    destroy: function () {
        // Cleans up all forms and elements, removes validator-specific events
        this.currentValidator.resetForm();
        $(this.currentValidator.currentForm)
            .off(".validate")
            .removeData("validator")
            .find(".validate-equalTo-blur")
            .off(".validate-equalTo")
            .removeClass("validate-equalTo-blur")
            .find(".validate-lessThan-blur")
            .off(".validate-lessThan")
            .removeClass("validate-lessThan-blur")
            .find(".validate-lessThanEqual-blur")
            .off(".validate-lessThanEqual")
            .removeClass("validate-lessThanEqual-blur")
            .find(".validate-greaterThanEqual-blur")
            .off(".validate-greaterThanEqual")
            .removeClass("validate-greaterThanEqual-blur")
            .find(".validate-greaterThan-blur")
            .off(".validate-greaterThan")
            .removeClass("validate-greaterThan-blur");


    },

    errorPlacementBetter: function (error,element) {
        var find = '\\$';
        var re = new RegExp(find, 'g');
        betterErrorID = ($(error).attr('id')).replace(re, '_');
        $(error).attr('id', betterErrorID);

        element.attr('aria-describedby', ($(error).attr('id') + ' ' + element.attr('aria-describedby')).trim());
        element.attr('aria-invalid', 'true');

        //fix the id with dollarsigns...
        //this is because jquery validator automatically makes the error id the same as the input control name{-error} that it is validating.  
        //then jquery doesn't like ID's with a $ in them.. so we need to fix it before it gets added.  We remove it and add the new on so the
        // event listeners are still attached.
        var prevElementToError = $('#' + betterErrorID).prev();
        $('#' + $(error).attr('id')).remove();
        if (prevElementToError.length>0) {
            error.insertAfter(prevElementToError);
        } else {
            error.insertAfter(element);
        }
        
    },
    //wires up the validator for Beacon Modules
    beaconModuleValidator: function (moduleRules, useRecaptcha = false) {

        if (this.currentValidator !== null) {
            this.destroy();
        }
//hijack the existing errorPlacement and run my own after it.
        var f = moduleRules.errorPlacement;
        moduleRules.errorPlacement = function (error, element) {
            if (f) {
                f(error, element);
            }
                Beacon.JqueryValidators.errorPlacementBetter(error, element);
        }
        //we can't use the label type anymore because the control is aria-describedby not label for='inputid' anymore.
        moduleRules.errorElement = "span";

        if (useRecaptcha) {
            moduleRules.submitHandler = function (form, submitevent) {
                if (grecaptcha.getResponse()) {
                    /* After reCAPTCHA is done, it will call beaconCaptchaSubmit() (defined above), which will
                     * trigger the form submission again. Eventually, the logic will proceed back to this function.
                     * Seeing that reCAPTCHA _has_ run, it will submit the form.
                     */
                    document.getElementById('Form1').submit();
                } else {
                    /* The first time that this handler is called, reCAPTCHA will probably not have run. So trigger
                     * it manually here, and _do_ _not_ proceeed to submit the form. We have to wait for reCAPTCHA
                     * to do whatever first.
                     */
                    grecaptcha.execute();
                }
            };
        }
        
        this.currentValidator = $("form").validate(moduleRules);
        var isvalid = $("form").valid();
        if (isvalid) {
            //sometimes I need to do a form submit..

        } else {

            event.preventDefault();
            this.currentValidator.focusInvalid();
        }
    },
    buildDependentRadio: function (dependentLblName, dependentRdoName, controllingRadioUnRequiredValue, controllingRadio) {
        //wire up the onclick event for the controlling radio button to add errors and required *

        $('#' + controllingRadio).click(function () {
            

            var t = (t == null) ? $("#" + dependentLblName + " > abbr") : '<abbr class="important-note" title="required">*</abbr>';  //get the required * element
            if (Beacon.JqueryValidators.isRadioButtonRequired(controllingRadio, controllingRadioUnRequiredValue)) {

                t = (t.html() == undefined) ? '<abbr class="important-note" title="required">*</abbr>' : t;

                $("#" + dependentLblName).append(t);
                //if the dependentRdo Button isn't filled in then throw an error behind it.
                if (!Beacon.JqueryValidators.isRadioButtonRequired(dependentRdoName, "")) {
                    $('#' + dependentRdoName).addClass("error"); //if the controlling radiobutton has the dependsValue then throw an error
                }


            }
            else {
                $('#' + dependentRdoName).removeClass("error");
                $("#" + dependentLblName + " > abbr").remove();
                $('#' + dependentRdoName).removeClass("error");
                $('#' + dependentRdoName + '-error').remove();

                t.remove();
            }
        });
        $('#' + dependentRdoName).click(function () {
              $('#' + dependentRdoName).removeClass("error");
            $('#' + dependentRdoName + '-error').remove();

        });
    },
    isRadioButtonRequired: function (requiredRadioButtonName, unRequiredValue) {
       
        var radioButtonValue = ($('input[name="' + requiredRadioButtonName + '"]:checked').val());
        radioButtonValue = (radioButtonValue == undefined) ? "" : radioButtonValue;
       
        return (radioButtonValue != unRequiredValue);
    },

    isDate: function (txtDate) {
        var currVal = txtDate;
        if (currVal == '')
            return false;
        var rxDatePattern = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/;
        var dtArray = currVal.match(rxDatePattern);

        if (dtArray == null)
            return false;

        dtMonth = dtArray[1];
        dtDay = dtArray[3];
        dtYear = dtArray[5];

        if (dtMonth < 1 || dtMonth > 12)
            return false;
        else if (dtDay < 1 || dtDay > 31)
            return false;
        else if ((dtMonth == 4 || dtMonth == 6 || dtMonth == 9 || dtMonth == 11) && dtDay == 31)
            return false;
        else if (dtMonth == 2) {
            var isleap = (dtYear % 4 == 0 && (dtYear % 100 != 0 || dtYear % 400 == 0));
            if (dtDay > 29 || (dtDay == 29 && !isleap))
                return false;
        }
        return true;
    },

    isEmail: function (value, element) {

        return /^\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\b$/.test(value);


    },
    isNumber: function (value, element) {

        return !isNaN(value);
    },
    isPDFFile: function (value, element) {

        return /([a-zA-Z0-9]+\.pdf)$/.test(value);
    }
    ,
    isPhoneNumber: function(value,element){
        validPhone =/^[1-9]\d{2}-\d{3}-\d{4}$/.test(value);
        //console.log("is phone valid?: " + validPhone)
        return validPhone;
    },
    isCityStateZip:function(value,element){
        validCityStateZip =/^\s*[a-zA-Z\s]+,+\s(A[LKSZRAP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[ADL N]|K[SY]|LA|M[ADEHINOPST]|N[CDEHJMVY]|O[HKR]|P[ARW]|RI|S[CD] |T[NX]|UT|V[AIT]|W[AIVY])\x20(?!0{5})\d{5}(-\d{4})?$/.test(value);
       // console.log("is city state zipe valid?: " + validCityStateZip)
        return validCityStateZip;
    },
    zipCode: function (value, element) {
        return /^[0-9]{5}(-\d{4})?$/.test(value) || value === '';
    },

    CLASS_NAME: "Beacon.WCAG"
};


